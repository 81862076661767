// Style
import "@/assets/styles/index.scss";

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from "./mixins";

import Vue3TouchEvents from "vue3-touch-events";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'


const app = createApp(App);
  app.use(store)
  app.use(router)
  app.use(Vue3TouchEvents)
  app.mixin(mixins)
  app.mount('#app')
