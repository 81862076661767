<template>
  <div class="main">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">

</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppComponent',
  components: {},
  setup() {},
  beforeCreate() {},
  created() {},
  mounted() {},
  computed: {},
  validations() {},
  methods: {}
});
</script>
